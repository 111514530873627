/////////////////////////////////////////////////////////////////////////////////////////
// Created on: 2018-01-26
// Original author: W. Alex Livesley
// Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
/////////////////////////////////////////////////////////////////////////////////////////
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

@import "./common.scss";
// $dark-mode-bg: #161616;
// $dark-mode-lighter: #1b1b1b;
// $dark-mode-much-lighter: #383838;
// $dark-mode-black: #202020;
// $dark-empty-placeholder: rgba(255,255,255,0.05);
// $dark-input: #313131;
// $dark-input-placeholder: rgba(255, 255, 255, 0.22);
// $danger: #f64a6b;
// $snow-white: #fafafa;
// $radivision-blue: #4cb4e7;
// $radivision-dk-gray: #32323b;
// $radivision-image-border: #f0f0f0;
// $radivision-lt-gray: #f2f4f4;
// $skyblue: #4cb4e7;
// $dark-gray: #32323b;
// $light-gray: #f2f4f4;
// $gray: #4a4a4f;
// $darker-gray: #25252a;
// $logo-headshot-border: rgba(white, 0.3);
// $moveLeft: -(200 * (1.2 - 1) / 2);
// $moveRight: 200 * (1.2 - 1);
// $highlight: #fffa57;

// $font-size: 26px;
// $line-height: 1.2;
// $lines-to-show: 3;

@import "~bootstrap/scss/bootstrap.scss";
// @import "../../node_modules/graphiql/graphiql.css";
@import "../../node_modules/video.js/dist/video-js.css";
@import "./inner-pages/my-account/account-settings.scss";
@import "./inner-pages/pitch-video-preview.scss";
@import "./inner-pages/my-account/my-saved-items.scss";
// @import "./inner-pages/companies-and-organizations.scss";
// @import "./inner-pages/nominate.scss";
// @import "./inner-pages/on-curated-section.scss";

// @import "./slider/content.scss";
// @import "./slider/mark.scss";
// @import "./slider/show-details-button.scss";
// @import "./slider/slider-button.scss";
// @import "./slider/slider-item.scss";
// @import "./slider/slider.scss";

// @import "./lists/feeds-list.scss";
// @import "./lists/config-feed.scss";
// @import "./lists/five-by-two-grid.scss";
@import "./lists/hero-carousel.scss";
// @import "./lists/instagram-feeds-list.scss";
// @import "./lists/lists-of-lists.scss";
// @import "./lists/mobile-carousel.scss";
@import "./lists/grid-view.scss";
// @import "./lists/social-media-feeds.scss";
// @import "./lists/twitter-feeds-list.scss";
// @import "./lists/wide-carousel.scss";
@import "./lists/rdv-carousel.scss";
@import "./lists/pitch-grid.scss";
@import "./lists/mixed-carousel.scss";
@import "./page-framework/about-us.scss";
@import "./about-us.scss";
@import "./page-framework/contact-us.scss";
@import "./page-framework/faqs.scss";
@import "./page-framework/footer-controller.scss";
@import "./page-framework/footer.scss";
@import "./page-framework/nav-bar.scss";
// @import "./page-framework/nprogress.scss";
@import "./page-framework/press.scss";
@import "./page-framework/privacy-policy.scss";
@import "./page-framework/report-bug.scss";
@import "./page-framework/scroll-to-top-button.scss";
@import "./page-framework/rdv-button.scss";
@import "./page-framework/toaster.scss";
@import "./page-framework/search-component.scss";
@import "./page-framework/search-filters.scss";
@import "./page-framework/technical-issues.scss";
@import "./page-framework/terms-and-conditions.scss";
// @import "./panels/did-we-miss-something.scss";
@import "./panels/on-panel.scss";
@import "./panels/panel-navbar.scss";
@import "./panels/marketing-panel.scss";
@import "./panels/marketing-overlay-panel.scss";
@import "./panels/item-panel.scss";
@import "./panels/pitch-panel.scss";
@import "./panels/small-pitch-panel.scss";
@import "./people-profile-header/change-background.scss";
@import "./people-profile-header/profile-header.scss";
@import "./profile-details-tab/details-tab.scss";
// @import "./profile-details-tab/dna-tab.scss";
// @import "./profile-details-tab/entity-north-star-editable-tab.scss";
// @import "./profile-details-tab/entity-north-star-tab.scss";
@import "./profile-details-tab/my-media-tab.scss";
// @import "./profile-details-tab/overview-tab.scss";
// @import "./profile-details-tab/person-north-star-tab.scss";
// @import "./profile-details-tab/spectator-account-settings.scss";
// @import "./profile-details-tab/spectator-notification-tab.scss";
@import "./profile-details-tab/portfolio.scss";
@import "./user-widgets/share-button.scss";
@import "./user-widgets/inline-loader.scss";
@import "./site-fonts.scss";
@import "./bootstrap-float-label.css";
@import "./invitation-panel.scss";
@import "./campaigns/xxtra.scss";
@import "./comment-area.scss";
@import "./mini-hero.scss";
@import "./page-framework/profile-picture-name.scss";
@import "./page-framework/external-pages.scss";
@import "./user-widgets/filters-widget.scss";
@import "./inner-pages/editorials/editorials-panel.scss";
@import "./inner-pages/investment-notification-form.scss";
@import "./page-framework/add-to-saved-button.scss";
@import "./page-framework/checkbox.scss";
@import "./page-framework/html-page-loading.scss";
@import "./tooltip.scss";

#breathing-image {
  width: 95%;
  max-width: 256px;
  -webkit-animation: breathing 7s ease-out infinite normal;
  animation: breathing 7s ease-out infinite normal;
  font-size: 24px;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  border-radius: 3px;
  text-align: center;
}

@-webkit-keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}

::-webkit-scrollbar {
  background: transparent;
  width: 6px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: transparent;
  width: 6px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(76, 180, 231, 0.7);
  opacity: 0.7;
  border-radius: 2px;
  height: 2px;
}

.sm-scroller::-webkit-scrollbar,
.modal .content::-webkit-scrollbar {
  background: transparent !important;
  width: 3px !important;
  height: 3px !important;
}

.sm-scroller::-webkit-scrollbar-track,
.modal .content::-webkit-scrollbar-track {
  background: transparent !important;
  width: 3px !important;
  height: 3px !important;
}

.sm-scroller::-webkit-scrollbar-thumb,
.modal .content::-webkit-scrollbar-thumb {
  background-color: rgba(100, 100, 100, 0.5) !important;
  opacity: 0.5 !important;
  border-radius: 2px !important;
  height: 2px !important;
}

html {
  height: -webkit-fill-available;
}

body {
  position: relative;
  font-family: "Muli", sans-serif;
  background-color: $dark-mode-bg;
  color: #fff;
  overflow: hidden;
  max-height: 100vh;
  max-height: -webkit-fill-available;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  display: block;
  // color: #fff;

  #root {
    position: relative;
    overflow: hidden;
    max-height: 100vh;
    max-height: -webkit-fill-available;
    width: 100vw;
    display: block;

    & > div:not(.modal),
    & > div:not(.modal) > div:not(.modal) {
      width: 100vw;
      display: flex;
      flex-flow: column;
    }
  }

  .main-container {
    position: relative;
    overflow: hidden;
    width: 100vw;
    max-height: 100vh;
    max-height: -webkit-fill-available;
    display: block;
    flex-direction: column;
    justify-content: flex-start;
  }

  &.allow-scroll {
    overflow-x: hidden;
    overflow-y: auto;
    overflow-y: overlay;
    -webkit-overflow-scrolling: touch;
    max-height: 100vh;

    #root {
      overflow-x: hidden;
      overflow-y: auto;
      max-height: none;
    }

    .main-container {
      overflow-x: hidden;
      overflow-y: auto;
      max-height: none;
    }
  }
}

.alert {
  text-align: left;
  display: flex;
  align-items: center;

  a {
    margin-left: 5px;
  }

  span:first-child {
    flex: 1;
  }

  span.dismiss-alert {
    cursor: pointer;
    line-height: 13px;

    svg {
      width: 12px;
      height: 12px;
      fill: $dark-gray;
      transition: all 0.3s;
    }

    &:hover {
      svg {
        fill: #32323b;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    font-size: 0.8rem;
  }

  &.hide {
    display: none;
  }

  &.floating {
    position: fixed;
    width: 90%;
    margin-left: 50%;
    transform: translateX(-50%);
    max-width: 720px;
    z-index: 100;
    top: -50px;
    animation: slideDown 0.3s ease-out;
    animation-fill-mode: forwards;
  }

  @keyframes slideDown {
    0% {
      top: -50px;
    }

    100% {
      top: 70px;
    }
  }
}

.play-icon-circle-solid {
  fill-rule: evenodd;
  clip-rule: evenodd;
}

.upcoming-shows-alert {
  display: flex;
  width: 100%;
  margin: 0 auto;
  align-items: center;

  .alert-success {
    z-index: 99;
    display: none;
    margin: 0rem auto;
  }

  .alert-danger {
    z-index: 99;
    margin: 0rem auto;
  }

  .be-block {
    display: block;
  }
}

#change-password-form {
  .alert-success {
    z-index: 99;
    display: none;
    margin: 1rem auto;
  }

  .alert-danger {
    z-index: 99;
    margin: 1rem auto;
  }
}

.nominate-panel {
  .alert-success {
    z-index: 99;
    display: none;
    margin: 0;
  }

  .alert-danger {
    margin: 0;
  }

  .be-block {
    display: inline-block;
  }
}

button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none;
  box-shadow: none !important;
}

.input-with-icon {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;

  span {
    position: absolute;
    display: block;
    width: 24px;
    height: 24px;
    left: 18px;
    width: 18px;
    height: 18px;
    top: calc(50% - 12px);
    z-index: 1;
    &.clickable {
      cursor: pointer;
    }
    svg {
      fill: #aaaaaa;
      width: 20px;
      height: 20px;
    }
  }
  &.is-invalid {
    span {
      left: 30px;
    }
  }

  input {
    padding-left: 52px !important;
    padding-right: 22px !important;
  }

  &.right-aligned {
    span {
      right: 18px;
      left: unset;
    }
    &.is-invalid {
      span {
        right: 30px;
        left: unset;
      }
    }
    input {
      padding-right: 52px !important;
      padding-left: 22px !important;
    }
  }

  &.icon-accent {
    svg {
      fill: $radivision-blue;
    }
  }
}

.rdv-star-icon {
  svg {
    .cls-1 {
      fill: #a6a8ab;
    }

    .cls-2 {
      fill: #4fb4e6;
    }

    .cls-3 {
      fill: #fff;
    }
  }
}

img {
  max-width: 100%;
}

#unsupported {
  display: none;
}

.unsupported {
  background: #f0f2f4;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .rdv-logo {
    width: 100%;
    padding: 8px 0;
    padding-left: 45px;
    background: $radivision-dk-gray;

    img {
      height: 46px;
      width: auto;
      margin-right: 2px;
    }

    span {
      text-transform: uppercase;
      font-size: 8px;
      letter-spacing: 0.075rem;
      // letter-spacing: 1.3px;
      color: $radivision-blue;
      vertical-align: bottom;
      display: inline-block;
    }
  }

  .unsupported-msg {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .unsupported-img-container {
      display: flex;
      align-items: center;
      justify-content: center;

      @media (min-width: 576px) {
        justify-content: flex-end;
      }

      .unsupported-img {
        padding: 24px 18px 24px 32px;
        width: 260px;
        height: 260px;
        margin-right: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #dddee1;
        border-radius: 50%;

        img {
          height: auto;
          width: 320px;
        }
      }
    }

    .unsupported-msg-info {
      align-self: flex-start;

      @media (min-width: 576px) {
        align-self: center;
      }

      span.old-browser,
      span#browser-recommended {
        display: none;
      }

      span#browser-recommended {
        padding-left: 24px;
      }

      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
}

body.ie-edge {
  #unsupported {
    display: flex;

    .unsupported-msg-info {
      span.no-js {
        display: none !important;
      }

      span.old-browser,
      span#browser-recommended {
        display: block;
      }
    }
  }
}

body.ie {
  #unsupported {
    display: block;

    #ie-sep {
      display: block !important;
      height: 5%;
      width: 100%;

      @media (min-width: 576px) {
        height: 15%;
      }
    }

    .unsupported-msg {
      & > div {
        float: left;
      }

      .unsupported-img-container {
        .unsupported-img {
          padding: 0;
          position: relative;
          margin: auto;

          @media (min-width: 576px) {
            float: right;
            margin-right: 24px;
          }

          img {
            margin: auto;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 18px;
            width: 210px;
            height: auto;
          }
        }
      }
    }

    .unsupported-msg-info {
      margin-top: 24px;
      padding-left: 48px;

      @media (min-width: 576px) {
        padding-left: 0;
        margin-top: 64px;
      }

      span.no-js {
        display: none !important;
      }

      span.old-browser,
      span#browser-recommended {
        display: block;
      }

      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
}

.loading-gradient {
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: -250px;
    top: 0;
    height: 100%;
    width: 250px;
    background: linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 0.03) 50%, transparent 100%);
    animation: loadingGradient 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
}

@keyframes loadingGradient {
  from {
    left: -250px;
  }
  to {
    left: 100%;
  }
}

.graphiql-container {
  height: 91vh;

  .doc-explorer-title-bar {
    height: 40px;
  }

  .topBar {
    height: 40px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: fixed;
  }

  ::-webkit-scrollbar-track {
    background-color: fixed;
  }
}

// #navigate {
//   .main-divider {
//     padding-bottom: 30px;
//   }
// }

.panels-padding {
  padding-top: 0px;
  padding-right: 0;
  padding-left: 0 !important;
  margin-bottom: 0px;

  .tab-content {
    width: 100%;
    max-width: 100%;
  }
}

.panels-padding.container-fluid > *:not(.tab-content) {
  margin: 0 70px;
  margin-top: 0px;

  @include media-breakpoint-down(md) {
    margin: 0 35px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    margin: 0 35px;
  }

  @include media-breakpoint-down(sm) {
    margin: 0 35px;
  }
}

.panels-padding:not(.on-curated-section) > *:not(.tab-content) > ul {
  margin-left: 70px;
  margin-right: 70px;
}

.panels-padding.on-curated-section > *:not(.tab-content) > ul {
  margin-left: 70px;
  margin-right: 70px;

  @media (min-width: 768px) and (max-width: 1024px) {
    margin-left: 35px;
    margin-right: 35px;
  }

  @include media-breakpoint-down(md) {
    margin-left: 35px;
    margin-right: 35px;
  }

  @include media-breakpoint-down(sm) {
    margin-left: 35px;
    margin-right: 35px;
  }

  @media (width: 1024px) {
    margin-left: 35px;
    margin-right: 35px;
  }
}

#growth_marketing_guide,
#equity_iq,
#unicorns_of_north_america {
  .tab-content {
    .grid-view {
      padding-left: 70px;
      padding-right: 70px;
      margin: 0;
      margin-top: 20px;

      @include media-breakpoint-down(sm) {
        padding-left: 35px;
        padding-right: 35px;
      }
    }
  }
}

.panels-padding > *:not(.tab-content) {
  margin: 0;
}

.img-fluid {
  @include media-breakpoint-down(md) {
    width: 80%;
  }
}

.nav.dropdown {
  margin: 0 !important;
}

.nav.nav-tabs.panel-nav,
.nav.dropdown {
  margin-top: 10px;

  @media (max-width: 997px) {
    margin-top: 10px;
    overflow-x: auto;
    box-sizing: content-box;
    z-index: 5;
    white-space: nowrap;
    display: block;
    overflow-y: hidden;
    scrollbar-width: auto;
    scrollbar-width: thin;
    scrollbar-width: none;
  }
}

.nav.nav-tabs.panel-nav::-webkit-scrollbar {
  display: none;
}

.nav.nav-tabs.panel-nav::-webkit-scrollbar-thumb {
  display: none;
}

.nav-tabs .nav-item {
  margin-bottom: 0px;

  @media (max-width: 997px) {
    display: inline-block;
    position: relative;
  }

  // .nav-link {
  //   &.active {
  //     // padding: 0.75rem 0;

  //     // @media (min-width: 2000px) {
  //     //   padding: 1.4rem 0;
  //     // }

  //     // height: 100%;
  //   }
  // }
}

.hollow-btn {
  padding: 15px 50px;
  font-weight: bold;
  // letter-spacing: 1px;
  color: white;
  margin-right: 10px;
  border-color: $radivision-blue;
  background-color: transparent;

  &:focus,
  &:hover {
    background-color: $radivision-blue;
    color: white;
  }
}

.view-more-btn {
  margin: auto;
  display: block;
  // margin-bottom: 30px;

  @media (min-width: 2500px) {
    font-size: 22px;
  }

  @include media-breakpoint-down(sm) {
    // margin-bottom: 20px;

    font-size: 12px;
    // margin-top: -10px !important;
  }

  @media (min-width: 1100px) and (max-width: 1440px) {
    margin-bottom: 15px;
  }
}

.episode-info span {
  margin-right: 8px;
  font-weight: 100;
  font-size: 12px;

  &:first-child {
    font-weight: 600;
  }

  @media (min-width: 2000px) {
    font-size: 20px;
  }
}

.panel-title {
  font-weight: 800;
  margin-bottom: 0;
  font-size: 18px;
  letter-spacing: 0.075rem !important;
  font-family: MuliBold;
  line-height: 36px;
  color: white;

  @media (min-width: 1000px) {
    font-size: 22px;
    line-height: 36px;
  }

  @media (min-width: 1600px) {
    font-size: 26px;
    line-height: 36px;
  }
}

.panel-title-padding {
  padding-left: 20px;
  padding-top: 40px;

  @include media-breakpoint-down(sm) {
    padding-top: 0;
  }
}

.panel-subtitle {
  padding-left: 0;
  font-weight: 600;
  margin-bottom: 0px;
  font-size: 22px;
  margin-top: 10px;
  color: white;
  @media (min-width: 2000px) {
    font-size: 26px;
    max-width: 55% !important;
  }

  // @include media-breakpoint-up(lg) {
  //   max-width: 56%;
  // }

  @media (min-width: 1500px) and (max-width: 1536px) {
    max-width: 55%;
    font-size: 19px;
  }

  @media (max-width: 1440px) {
    font-size: 17px;
    max-width: 55%;
  }

  @media (width: 1024px) {
    max-width: 50%;
  }

  @media (min-width: 1360px) and (max-width: 1366px) {
    max-width: 55%;
  }

  @include media-breakpoint-down(md) {
    font-size: 14px;
    max-width: 50%;
  }

  @include media-breakpoint-down(sm) {
    font-size: 13px;
    margin-bottom: 5px !important;
    max-width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1169px) {
    font-size: 14px;
  }
}

.grid-description {
  @include media-breakpoint-up(lg) {
    max-width: 56%;
  }
}

//STYLES for the divider between homepage's components --start
#the-shows-section {
  .main-divider {
    position: relative;
    padding-top: 0px;
    padding-bottom: 45px;
    margin: 0 70px;

    @include media-breakpoint-down(md) {
      margin: 0 35px;
    }

    // @include media-breakpoint-down(sm) {
    //   padding-bottom: 0px;
    // }

    @include media-breakpoint-down(xs) {
      margin: 0 35px;
      // padding-bottom: 10px;
    }
  }
}

.main-divider {
  position: relative;
  padding-top: 0px;
  padding-bottom: 0;
  margin: 0 70px;

  // @include media-breakpoint-down(xs) {
  //   padding-top: 15px;
  //   padding-bottom: 15px;
  // }

  // @include media-breakpoint-down(sm) {
  //   padding-bottom: 15px;
  // }

  // @media (min-width: 1170px) and (max-width: 1280px) {
  //   margin-bottom: 30px;
  // }

  @include media-breakpoint-down(md) {
    margin: 0 35px;
    // margin-bottom: 0;
    // padding-bottom: 0;
  }

  @media (min-width: 768px) and (max-width: 1169px) {
    margin: 0 35px;
    // margin-bottom: 30px;
  }

  hr {
    border-width: 4px;
    border-color: $radivision-lt-gray;
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -2px;

    // @include media-breakpoint-up(sm) {
    //   margin-top: -2px;
    // }

    // @include media-breakpoint-down(sm) {
    //   margin-top: 13px;
    // }

    z-index: -2;

    // @media (min-width: 2500px) {
    //   border-width: 6px;
    //   width: calc(100% - (50% + 300px));
    //   margin-top: -3px;
    // }

    &:last-child {
      right: 0;
      left: auto;
    }

    // @include media-breakpoint-down(xs) {
    //   display: none;
    // }
  }

  .subtitle {
    color: #a5a8ac;
    font-size: 25px;
    font-family: "MuliExtraBold";
    letter-spacing: 0.075rem;

    @include media-breakpoint-down(sm) {
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    @media (min-width: 2000px) {
      font-size: 28px;
    }
  }

  span {
    font-size: 40px;
    letter-spacing: 0.075rem;
    font-family: "MuliExtraBold";
    background: #fff;
    z-index: 99;
    // padding: 0 35px;
    background: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    align-content: center;
    justify-content: center;

    &:after,
    &::before {
      width: 100%;
      border-bottom: solid 4px $radivision-lt-gray;
      content: "";
      flex: 1 1;
      margin: auto;
    }

    &:after {
      margin-left: 25px;

      @include media-breakpoint-down(sm) {
        margin-left: 15px;
      }
    }

    &::before {
      margin-right: 25px;

      @include media-breakpoint-down(sm) {
        margin-right: 15px;
      }
    }

    @media (min-width: 2500px) {
      font-size: 50px;
    }

    @media (min-width: 1537px) and (max-width: 2499px) {
      font-size: 40px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 22px;
      // padding: 0 6px;
      letter-spacing: 0.05rem;
    }
  }

  &.ecosystem-title {
    @include media-breakpoint-down(sm) {
      margin-left: 35px;

      hr:first-child {
        display: none;
      }

      span {
        display: block;
        font-size: 20px;
      }
    }
  }

  .on-curated-header {
    @include media-breakpoint-down(sm) {
      padding: 0 35px;
    }
  }

  @media (min-width: 768px) and (max-width: 1169px) {
    span {
      font-size: 24px !important;
    }

    .subtitle {
      font-size: 16px !important;
    }

    // hr {
    //   width: calc(100% - (42% + 200px));
    // }
  }
}

.main-divider div:first-child {
  position: relative;
  margin: 30px 0;

  // @include media-breakpoint-down(md)  {
  //   margin-top: 15px;
  // }
}

.main-divider > hr.sub-divider {
  margin-bottom: 60px;

  @include media-breakpoint-down(md) {
    margin-bottom: 30px;
  }
}

#show-new-members.has-sep {
  padding-bottom: 0;
}

// STYLES for the divider between homepage's components --end
//workaround for multi-line ellipsis --start

.multiline-ellipsis {
  overflow: hidden;
  position: relative;
  display: -webkit-box;
  margin: 0 auto;
  line-height: $line-height;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;

  span:after {
    content: "\0000a0";
    position: absolute;
    width: 1000px;
    z-index: 1;
  }
}

//workaround for multi-line ellipsis --end
//gray panel navbar --start

.gray-header {
  background-color: $gray;
  padding: 40px 40px 0;
  color: white;

  .title {
    font-weight: 800;
  }

  .subtitle {
    // letter-spacing: 0.75rem;
    line-height: 22px;
    font-weight: 100;

    @include media-breakpoint-up(lg) {
      max-width: 50%;
    }
  }

  .panel-nav {
    border-color: $radivision-dk-gray;
    margin-bottom: 0;
    margin-left: 20px;
    margin-top: 60px;

    .nav-item {
      margin-bottom: -2px;
    }

    .nav-link {
      color: white;
      // letter-spacing: 0.075rem;

      &.active {
        background-color: transparent;
        padding: 0.8rem 0;
      }
    }

    .search-container {
      input {
        background-color: transparent;
      }
    }
  }
}

//gray panel navbar --end
//gradient image effect --start

.gradient {
  img {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 70%;
    object-fit: cover;

    @media (max-width: 560px) {
      width: 100%;
    }
  }

  &::after {
    background: linear-gradient(to right, #32323b 12.5%, transparent);
    background: linear-gradient(to right, #32323b 12.5%, transparent);
    display: inline-block;
    height: 100%;
    width: 13%;
    content: "";
    position: absolute;
    top: 0;
    left: 30%;

    @media (max-width: 560px) {
      left: 0;
    }
  }
}

// gradient image effect --end
// loading spinner --start
.loader-container {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 98;
  width: 100vw;
  height: 100vh;
  height: -webkit-fill-available;
  align-items: center;
  justify-content: center;
  opacity: 0.9;
  margin: 0 auto;
  background-color: $dark-mode-bg;

  &.spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(22, 22, 22, 0.56);
  }
}

.ring-loader {
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    margin: 0px;
    border: 2px solid #fff;
    border-radius: 50%;
    animation: ring-loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  @keyframes ring-loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

// loading spinner --end
// .text-uppercase {
//   letter-spacing: 0.075rem;
// }

.dk-gray {
  color: #32323b;
}

.bg-dk-gray {
  background-color: #32323b;
}

.gray {
  color: #f2f4f4;
}

.bg-gray {
  background-color: #f2f4f4;
}

.lt-gray {
  color: #fafafa;
}

.bg-lt-gray {
  background-color: #fafafa;
}

.blue {
  color: #4cb4e7;
}

.bg-blue {
  background-color: #4cb4e7;
}

.bg-transparent {
  background: transparent;
}

.align-self-center {
  align-self: center;
}

.font-weight-800 {
  font-weight: 800 !important;
}

@for $i from 12 through 24 {
  .font-#{$i} {
    font-size: $i + px !important;
  }
}

.content-right {
  position: absolute;
  right: 0px;
  bottom: 40%;
  margin: 0 auto;

  a {
    margin-right: 10px !important;
  }

  &.copyrights {
    @media (min-width: 2500px) {
      font-size: 1.5rem;
    }
  }

  .btn-radivision {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .handleButton {
    background: #4cb4e7 !important;
  }

  .display_InlineBlock {
    @include media-breakpoint-down(md) {
      .share {
        display: inline-block !important;
      }
    }

    .btn-radivision {
      margin-top: 0px;
      margin-bottom: 0px;
      background: #4cb4e7 !important;

      svg {
        display: none;
      }
    }

    .share .share-list {
      bottom: -225px;
      top: auto;

      @media (min-width: 2500px) {
        bottom: -255px;
      }

      &:after {
        content: "";
        border-width: 1px 0 0 1px;
        top: -11px;
      }
    }

    .visible {
      display: block;
      top: auto;
      bottom: 0;
      opacity: 1;
      animation: none;
    }
  }

  @include media-breakpoint-down(md) {
    font-size: 12px;
  }

  @include media-breakpoint-down(sm) {
    position: unset;
  }
}

.btn-outline {
  color: #32323b;
  background-color: transparent;
  background-image: none;
  border-color: #4cb4e7;
}

.btn-outline:active,
.btn-outline:focus,
.btn-outline:hover {
  color: #ffffff;
  background-color: #4cb4e7;
  border-color: #4cb4e7;
}

.cookie-consent-container {
  position: fixed;
  z-index: 100;
  color: white;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(50, 50, 59, 0.9);
  padding: 20px 70px;

  @include media-breakpoint-down(sm) {
    padding: 20px 35px;
  }

  .position-relative {
    @include media-breakpoint-down(sm) {
      padding-left: 0px;
    }
  }

  p {
    margin: 0;
    max-width: 70%;
    padding-left: 0px;

    @include media-breakpoint-down(sm) {
      max-width: 100%;
      font-size: 11px;
      margin-top: 15px;
    }
  }

  a {
    color: $radivision-blue;
    padding-left: 0px;

    @include media-breakpoint-down(sm) {
      font-size: 11px;
    }
  }

  .ginger {
    position: absolute;
    width: 45px;
    height: 55px;
    left: 0;

    @include media-breakpoint-down(sm) {
      position: relative;
      width: 42px;
      height: 55px;
      margin-top: 0;
      float: left;
      margin-right: 20px;
    }

    .ginger-inner {
      fill: none;
      stroke: white;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }

  .close-icon,
  .close-btn {
    width: 15px;
    height: 15px;
    fill: white;
    position: absolute;
    right: 5px;
    top: 0;
    cursor: pointer;

    @include media-breakpoint-down(sm) {
      margin-top: -20px;
      width: 12px;
      height: 12px;
    }
  }

  .btn-radivision {
    right: 18%;
    position: ABSOLUTE;
    top: 50%;
    margin: -21px 0 0;
    padding: 10px 20px;
    font-weight: 600;

    @include media-breakpoint-down(md) {
      right: 10%;
    }

    @include media-breakpoint-down(sm) {
      right: 0;
      font-size: 10px;
      position: relative;
      margin: 0 auto;
      float: right;
      margin-top: 10px;
    }

    // @media (min-width: 2000px) {
    //   margin: -24px 0 0;
    //   font-size: 18px;
    // }
  }
}

// varibles for Ops! shadow effect
$foreground-color: #b85b3f;
$shadow-color: $radivision-blue;
$distance: 12px;
$cut-distance: 6px;
$strips-size: 10px;
$strips-ratio: 70%;
$strips-angle: 45deg;

.error-msg {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  background: #f0f2f4;

  .rdv-logo {
    width: 100%;
    padding: 0;
    padding-left: 45px;
    background: $radivision-dk-gray;
    display: inline-block !important;

    img {
      max-width: 300px;
      display: inline-block;
      vertical-align: bottom;
    }

    span {
      text-transform: uppercase;
      font-size: 8px;
      // letter-spacing: 1.3px;
      letter-spacing: 0.075rem;
      color: $radivision-blue;
      vertical-align: bottom;
      display: inline-block;
    }
  }

  .error-msg-content {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    font-family: "Open Sans Condensed", sans-serif;
    font-size: 85pt;
    background-color: $radivision-lt-gray;
    text-align: center;
    line-height: 1.2em;

    .dashed-shadow {
      position: relative;
      top: $distance;
      left: $distance;
      display: inline-block;
      color: $shadow-color;
      font-weight: 700;
    }

    .dashed-shadow:before {
      content: " ";
      display: block;

      position: absolute;
      $bleeding-horizontal: 10px;
      $bleeding-vertical: 0px;
      top: -$bleeding-vertical - $distance;
      left: -$bleeding-vertical - $distance;
      bottom: -$bleeding-horizontal + $distance;
      right: -$bleeding-horizontal + $distance;
      z-index: 1;
      $color: $radivision-lt-gray;
      $size: calc($strips-ratio / 2);
      $halfSize: calc($size / 2);
      $p1: $halfSize;
      $p2: 50%-$halfSize;
      $p3: 50% + $halfSize;
      $p4: 100%-$halfSize;
      $transparent: transparentize($color, 1);
      background-image: linear-gradient(
        $strips-angle,
        $color $p1,
        $transparent $p1,
        $transparent $p2,
        $color $p2,
        $color $p3,
        $transparent $p3,
        $transparent $p4,
        $color $p4
      );
      background-image: linear-gradient(
        $strips-angle,
        $color $p1,
        $transparent $p1,
        $transparent $p2,
        $color $p2,
        $color $p3,
        $transparent $p3,
        $transparent $p4,
        $color $p4
      );
      background-size: $strips-size $strips-size;
      background-attachment: fixed;
    }

    .dashed-shadow:after {
      z-index: 2;
      content: attr(data-text);
      position: absolute;
      left: -$distance;
      top: -$distance;
      color: $foreground-color;
      text-shadow: $cut-distance $cut-distance $radivision-lt-gray;
    }

    .dashed-shadow-text {
      font-size: 140pt;
      line-height: 1.2em;
    }

    .shadow {
      font-size: 120pt;
      line-height: 1.28em;
      box-shadow: none !important;
    }

    & > p {
      font-size: 14px;
      margin-top: 20px;
      line-height: normal;
      margin-bottom: 120px;
      margin-left: 2%;

      a {
        cursor: pointer;
        color: $radivision-blue;
        transition: all 0.3s;
        margin: 0 4px;
        font-weight: bold;

        svg {
          width: 14px;
          height: 14px;
          fill: $radivision-blue;
          vertical-align: top;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.loaderOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 4px;
  cursor: default;
}

.loaderButton {
  color: #ffffff;
  font-size: 18px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 0 auto;
  position: absolute;
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  right: 20px;
  top: 18px;
}

@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em,
      -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }

  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em,
      -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }

  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }

  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em,
      -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }

  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em,
      -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }

  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }

  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

label.form-group {
  padding: 0;
}

#emailLogin {
  .btn-radivision {
    padding: 14px 30px;
  }
}

.video-js {
  width: 100%;
  height: 100%;
}

.card-footer small {
  margin-top: 0;
}

#rdv-carM5u4UbNgSyO9uls2TVaWDw,
#rdv-carfx4dlgUNS228FiILHy2V0w {
  .publisher {
    display: none;
  }
}

.search-container {
  @media (min-width: 1023px) and (max-width: 1171px) {
    display: none;
  }
}

.form-sep {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;

  span.line {
    flex: 1;
    height: 1px;
    background-color: $radivision-dk-gray;
  }

  span.text {
    font-size: 14px;
    font-weight: bold;
    margin: 0 10px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.85);
    letter-spacing: 0.075rem;
  }
}

.not-Modal .has-float-label,
.has-float-label,
label.company-name {
  padding: 0;
}

#my-account .space-divider {
  .form-group {
    margin-bottom: 0.5rem;
  }
}

.sub-divider {
  width: 100% !important;
  margin-bottom: 30px;
  position: relative !important;
}

.overlay {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.ticker-tape {
  width: 100%;
  background: #fff;
  height: 45px;
  white-space: nowrap;
  overflow: hidden;

  @media (min-width: 2000px) {
    height: 67px;
  }

  .ticker-tape_image {
    width: 115px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1040;

    @media (min-width: 2000px) {
      width: 130px;
    }

    @include media-breakpoint-down(sm) {
      width: 80px;
    }
  }

  .ticker {
    height: 45px !important;

    h1 {
      margin-bottom: 0;
      line-height: 36px;

      @media (min-width: 2000px) {
        line-height: 59px;
      }

      a {
        font-size: 18px;
        padding-left: 70px;
        color: black;
        cursor: pointer;
        text-decoration: none;
        white-space: nowrap;
        vertical-align: middle;

        @media (min-width: 2000px) {
          font-size: 1.5rem;
        }

        @media (min-width: 1366px) and (max-width: 1536px) {
          font-size: 1rem;
        }

        @media (min-width: 768px) and (max-width: 1024px) {
          font-size: 17px;
        }

        @include media-breakpoint-down(sm) {
          font-size: 12px;
          padding-left: 40px;
        }
      }
    }

    @media (min-width: 2000px) {
      height: 67px !important;
    }

    @include media-breakpoint-down(sm) {
      height: 40px !important;
    }
  }

  .close-ticker-tape {
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 70px;
    height: 45px;
    background: #fff;
    line-height: 45px;
    cursor: pointer;

    @media (min-width: 2000px) {
      height: 64px;
      line-height: 64px;
    }

    @include media-breakpoint-down(sm) {
      width: 40px;
      height: 40px;
      line-height: 40px;
    }

    svg {
      width: 28px;
      height: 28px;
      fill: #d4d4d4;

      @media (min-width: 2000px) {
        width: 32px;
        height: 32px;
      }

      @include media-breakpoint-down(sm) {
        width: 20px;
        height: 20px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    height: 40px;
  }
}

.list-modal {
  .modal-body {
    padding-right: 0;

    .list-container {
      padding-right: 35px !important;
      overflow: auto;
      overflow-x: hidden;
      max-height: calc(100vh - 320px);
    }
  }

  .modal-footer {
    padding-right: 35px !important;
  }

  &.modal-lg,
  &.modal-xl {
    .modal-body {
      .list-container {
        padding-right: 40px !important;
      }
    }

    .modal-footer {
      padding-right: 40px !important;
    }
  }
}

.modal-footer {
  .row {
    margin-right: 0;
    margin-left: 0;

    .cancel-btn {
      background: #646464 !important;
    }
  }
}

.claimDiscription {
  font-weight: bold;
  margin-bottom: 30px !important;
}

input:disabled,
button:disabled {
  cursor: not-allowed;
}

.form-group.alert {
  display: flex;
  align-items: center;

  h6 {
    flex: 1;
    margin: 0;
  }

  .hide-msg {
    width: 24px;
    height: 24px;
    margin-right: -10px;

    svg {
      width: 18px;
      height: 18px;
      vertical-align: unset;
    }
  }
}

.recoverHeight {
  height: 100%;
  width: 100%;
  background: #161616;

  @media (min-width: 1024px) {
    height: 91vh;
  }
}

.modal-lg,
.modal-xl {
  @media (min-width: 2000px) and (max-width: 2200px) {
    max-width: 950px;
  }
}

.eventBrite {
  .d-md-block {
    z-index: 999;
    right: 70px;
    left: auto;
    bottom: auto;
    top: 15%;
    position: absolute;
    width: 26%;
    height: 70%;
    background: rgba(25, 25, 30, 0.85);
    // margin: 20% auto;
    text-align: left;
    font-family: "Muli";
    padding: 70px;

    .date {
      color: #4cb4e7;
      // display: inline-block;
    }

    h1,
    h2 {
      font-size: 2.1rem;
      color: white;
      font-family: "MuliBold";
      text-transform: uppercase;
      letter-spacing: 0.075rem;
      margin-bottom: 0;
    }

    .truncate {
      display: inline-block;
      font-size: 14px;
      color: #fff !important;

      br {
        display: none;
      }

      margin-top: 20px;
    }

    .btn-root {
      border: 0;
      background: #4cb4e7;
      color: white;
      padding: 10px 20px;
      font-weight: bold;
      width: auto;
      margin: 0;
      border-radius: 4px;
      margin-top: 20px;
    }
  }
}

span.highlight {
  // background: linear-gradient(transparent 30%, $highlight 70%);
  font-weight: bolder;
  color: #8b8b8b;
}

input:-moz-ui-invalid:not(:focus),
input:-moz-submit-invalid:not(:focus),
input:required {
  box-shadow: none;
}

.video-panel.full-width-video {
  height: 100%;

  & div.video-container,
  & div.video-container > div,
  & div.video-container > div > div,
  & div.youtube-video,
  & div.youtube-video .video,
  & div.youtube-video .video > div {
    width: 100%;
    height: 100%;
  }
}

.pip-player {
  z-index: 100;
  position: fixed;
  width: 450px;
  height: 300px;
  bottom: 30px;
  right: 80px;
  background: #000;
}
.pip-player .close,
.pip-player .handle {
  position: absolute;
  right: 0px;
  top: -30px;
  width: 32px;
  height: 32px;
  background: #000;
  color: #fff;
  opacity: 1;
  cursor: pointer;

  & > i {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    background-color: transparent;
    border-radius: 50%;
    cursor: pointer;
    svg {
      width: 18px;
      height: 18px;
      fill: white;
    }
  }
}
.pip-player .handle {
  right: 32px;
  cursor: move;
}

.vjs-controls-disabled .vjs-big-play-button,
.vjs-has-started .vjs-big-play-button,
.vjs-using-native-controls .vjs-big-play-button,
.vjs-error .vjs-big-play-button,
.vjs-big-play-button,
.video-js .vjs-big-play-button {
  opacity: 0 !important;
  display: none !important;
  transition: none;
}

.video-panel {
  a.back {
    position: fixed !important;
    z-index: 999;
  }
}

.eventYT-video {
  height: 100%;

  .video {
    width: 100%;
    height: 100%;

    & > div:first-child {
      height: 100%;
    }

    // @media (min-width:1024px) {
    //   height: 91vh;
    // }

    iframe {
      // object-fit: cover;
      width: 100%;
      height: 100%;
      max-width: 100%;

      // @media (min-width:1024px) {
      //   height: 91vh;
      // }
    }
  }
}
textarea,
input[type="text"],
textarea:focus {
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none !important;
}
.more-line-break {
  margin: 10px 0px;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .br {
    border-bottom: 2px solid #555555;
    flex-grow: 1;
    flex: 1;
    height: 1px;
  }
  span {
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    margin: 0px 10px;
    height: 48px;
    width: 48px;
    border: 2px solid #555555;
    border-radius: 24px;
    transition: color 0.5s ease-in;
    &:hover {
      border: 2px solid #aaa;
      em {
        svg {
          fill: #aaa;
        }
      }
    }
    em {
      svg {
        fill: #555555;
        width: 18px;
      }
    }
  }
}
h2.no-content {
  margin-top: 72px;
  padding: 0 50px;
  padding-bottom: 35px;
  font-size: 18px;
  font-family: Muli;
  text-align: center;
  line-height: 24px;

  &.user-welcome {
    color: $radivision-blue;
    font-size: 20px;
  }
  @media (min-width: 560px) {
    padding: 0 20px !important;
  }
}

.how-to {
  margin-top: 30px;
}

.circles-about {
  display: flex;
  justify-content: space-evenly;
  gap: 24px;
  margin: 40px 0px;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }

  .circle-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    width: 18%;
    color: white;
    font-size: 14px;
    margin: 5px;

    .circle {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #4cb4e7;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: bold;
    }
  }
}
